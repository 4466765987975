import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = props => {
  const data = useStaticQuery(graphql`
    query DataQuery {
        dataJson {
          phone
          email
          data_button_link
        }
    }
   `);
  return (
    <div>
      {props.showButton && (
        <div>
          <a className="button">Coming soon!</a>
        </div>
      )}
    </div>
  );
};

export default Call;
